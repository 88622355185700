@import "~antd/dist/antd.less";
@primary-color: #51CE7F;
@font-size-base: 13px;

html,
body {
  margin: 0;
  font-family: "Kanit", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #e2e2e2;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

/* End Scrollbar */

.text-sm {
  font-weight: 100;
}

.text-md {
  font-weight: 300;
}

.text-lg {
  font-weight: 500;
}

/* End FontWeight */

/* Text Align */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* End Text Align */

/* Font Color */

.text-primary {
  color: @primary-color;
}

.text-purple {
  color: #945de2;
}

.text-dark-purple {
  color: #8030f0;
}

.text-grey {
  color: rgba(0, 0, 0, 0.45);
}

.text-dark-grey {
  color: #6a6a6a;
}

/* End Font Color */

/* Border */
.border-r {
  border-right: 2px solid #f0f0f0;
}

.border-l {
  border-left: 2px solid #f0f0f0;
}

.border-t {
  border-top: 2px solid #f0f0f0;
}

.border-b {
  border-bottom: 2px solid #f0f0f0;
}

/* End Border */

.login-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f0f2f5;
}

.login-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* filter: brightness(0.6); */
}

.login-logo {
  max-width: 352px;
  max-height: 147.48px;
  /* max-width: 100%;
  max-height: 1500px; */
}

.login-button {
  background: #78479c;
  border-color: #78479c;
  color: white;
}

.login-button:hover {
  background: #9f71c1;
  border-color: #9f71c1;
  color: white;
}

/* Table */
.table-row-pointer {
  cursor: pointer;
}

.table-column-hide {
  display: none;
}

.table-column-border-right {
  border-right: 2px solid #f0f0f0;
}

.col-border-right {
  border-right: 2px solid #f0f0f0;
}

.table-row-red {
  background-color: #fff4f4;
}

.table-column-red {
  background-color: #fff4f4;
}

.table-column-grey {
  background-color: #fbfbfb;
}

.table-row-disabled {
  background-color: #fafafa;
  opacity: 0.6;
}

.table-summary-top .ant-table-summary {
  display: table-header-group;
}

.table-unstyle-sorter td.ant-table-column-sort {
  background-color: transparent;
}

/* End Table */

/* Table Drag */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* EventPage */
.event-upload-form .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  height: 58.5px;
}

/* End EventPage */

/* Button */
// button {
//   border-color: #945de2;
// }
/* End Button */

/* Infinite Scroll */
.infinite-container {
  height: 300px;
  padding: 8px 24px;
  overflow-y: scroll;
  /* display: "flex";
  flex-direction: "column-reverse"; */
  /* border: 1px solid #e8e8e8;
  border-radius: 4px; */
}

/* Upload Antd */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 150px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.upload-list-broadcast .ant-upload-list-item {
  height: 50px;
}

.upload-list-note>.ant-upload-list.ant-upload-list-picture {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
}

/* End Upload Antd */

/* Chat Overrides Antd */
.chat-overrides {
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-tabs-tab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0;
  }

  .ant-notification-notice-message,
  .ant-notification-notice-description {
    margin-left: 64px;
  }
}

/* Chat Overrides Antd */

/* Card Antd */
.ant-card-bordered {
  box-shadow: 0px 0px 20px rgba(61, 15, 179, 0.08);
}

/* End Card Antd */

/* Tag House */

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}

/* Menu tooltip */
.ant-menu-inline-collapsed-tooltip>.ant-tooltip-content>.ant-tooltip-inner>a {
  color: white;
}

/* Antd datepicker */
.ant-picker-year-btn {
  pointer-events: none;
}

/* Antd Collapase */
#advanceSearch>.ant-collapse-header {
  display: none;
}

#advanceSearch>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
  padding-bottom: 12px;
}
@primary-color: #8F3535;