.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* Sidebar */

.sidebar-logo {
  text-align: center;
  font-size: 14px;
  color: white;
  height: 64.25px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.sidebar-mini-logo {
  display: none;
}

.ant-layout-sider-collapsed .sidebar-logo .sidebar-default-logo {
  display: none;
}

.ant-layout-sider-collapsed .sidebar-logo .sidebar-mini-logo {
  display: block;
}

.ant-layout-sider-collapsed .ant-image .ant-image-img {
  /* height: 40px !important; */
  width: 80px !important;
  object-fit: cover !important;
}

.ant-layout-sider-collapsed .sidebar-logo .sidebar-name {
  display: none;
}

/* End Sidebar */

.underline {
  margin: 0 8px 0 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.layout-space {
  margin: 0 16px;
  padding: 24px;
  min-height: 280px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none;
}